<template>
  <cs-options-menu text icon="$vuetify.icons.feed" :label="label" max-width="200">
    <template #menu>
      <div class="py-1">
        <a class="full-width" :href="`${config.api}/events/feeds?limit=0&feed_content=incident&feed_type=rss`">
          <v-icon small left>
            $vuetify.icons.meteoKO
          </v-icon>
          {{ $t('Incidents') }}
        </a>
      </div>
      <div class="py-1">
        <a class="full-width" :href="`${config.api}/events/feeds?limit=0&feed_content=maintenance&feed_type=rss`">
          <v-icon small left>
            $vuetify.icons.setup
          </v-icon>
          {{ $t('Maintenance') }}
        </a>
      </div>
      <div class="py-1">
        <a class="full-width" :href="`${config.api}/events/feeds?limit=0&feed_content=all&feed_type=rss`">
          {{ $t('All events') }}
        </a>
      </div>
    </template>
  </cs-options-menu>
</template>

<script>
import config from '../../config'

export default {
  name: 'FeedsMenu',
  props: {
    label: { type: String, default: '' }
  },
  data () {
    return {
      config
    }
  }
}
</script>
