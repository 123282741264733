<template>
  <v-sheet class="pa-2">
    <v-form ref="formEvent" v-model="valid">
      <v-row no-gutters>
        <v-col cols="12" md="4">
          <v-select v-model="event.service" :items="services" :label="$t('Affected service')" :rules="[$stratus.services.form.rules.required]" class="required">
            <template v-slot:selection="{ item }">
              <v-col>
                <v-icon color="primary" small>
                  {{ getEventCategoryIcon(item) }}
                </v-icon>
                <span class="pl-1">{{ $t(`service-name-${item}`) }}</span>
              </v-col>
            </template>
            <template v-slot:item="{ item }">
              <v-col>
                <v-icon color="menu-icon" left>
                  {{ getEventCategoryIcon(item) }}
                </v-icon>
                <span class="pl-1">{{ $t(`service-name-${item}`) }}</span>
              </v-col>
            </template>
          </v-select>
        </v-col>

        <v-col cols="12" md="8" class="pl-8">
          <v-radio-group v-model="event.type" :label="$t('Event type')" row class="ml-2">
            <v-radio :label="$t('Incident')" value="incident" />
            <v-radio :label="$t('Maintenance')" value="maintenance" />
          </v-radio-group>
        </v-col>

        <v-col cols="12">
          <v-row no-gutters>
            <v-col cols="12" md="3" v-if="statuses.length > 1">
              <div> {{ $t('Status') }} </div>
              <v-radio-group v-model="event.status" column dense class="pt-0">
                <v-radio v-for="(status, index) in statuses" :key="index+status" :label="$t(`event-status-${status}`)" :value="status" />
              </v-radio-group>
            </v-col>

            <v-col cols="12" md="3">
              <div>
                {{ $t('Criticality') }}
              </div>
              <v-radio-group v-model="event.criticity" column dense class="pt-0" :disabled="!showCriticity">
                <v-radio v-for="(criticity, index) in criticities" :key="index" :value="criticity">
                  <template v-slot:label>
                    {{ $t(`severity-${criticity}`) }}
                    <v-icon :color="getCriticityStyle(criticity).class">
                      {{ getCriticityStyle(criticity).icon }}
                    </v-icon>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>

            <v-col cols="12" md="6">
              <div>
                {{ $t('Period') }}
              </div>
              <v-row no-gutters>
                <v-col cols="10">
                  <cs-date-time-picker v-model="event.startDateTime" :date-label="$t('Start date')" dense :time-label="$t('Start time')" :rules="[$stratus.services.form.rules.required]" class="required" />
                </v-col>
                <v-col cols="10">
                  <cs-date-time-picker v-model="event.endDateTime" dense v-if="showEndDateInput" :date-label="$t('End date')" :time-label="$t('End time')" :rules="[$stratus.services.form.rules.required]" class="required" />
                </v-col>
                <v-col cols="6" v-if="showDurationInput">
                  <duration-input v-model="event.duration" />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="12">
          <cs-wysiwyg-textarea v-model="event.description" :placeholder="$t('Describe the interruption...')" :title="$t('Description')" :actions="['bold', 'underline', 'italic' ]" :min-length="1" :max-length="64000" />
        </v-col>
      </v-row>
    </v-form>
  </v-sheet>
</template>

<script>
import DurationInput from '@/components/DurationInput'
import { getEventCategoryIcon, getCriticityStyle, criticities } from '@/services/services'

export default {
  components: {
    'duration-input': DurationInput
  },
  props: {
    eventId: { type: String, required: true },
    event: { type: Object, required: true }
  },
  data () {
    return {
      criticities,
      getCriticityStyle,
      getEventCategoryIcon,
      isSaving: false,
      valid: false
    }
  },
  watch: {
    'event.type' () {
      this.event.status = (this.statuses.includes(this.event.status) && this.event.status) || this.defaultStatus
    },
    'event.criticity' () {
      if (this.showCriticity && this.event.criticity === 'high' && !this.event.description) {
        // No translation because the text would be translated on the admin's browser before the event creation,
        // which could differ from the regular user's browser language anyways. Keeping this hardcoded.
        this.event.description = 'Chers clients,\n\nNous rencontrons actuellement un incident impactant nos infrastructures.\nLes équipes sont mobilisées afin d\'identifier au plus vite la cause de l\'incident.\n\nNous vous tenons informés.\nLes équipes Scalair'
      }
    }
  },
  computed: {
    dark () { return this.$store.getters.dark },
    isLogged () { return this.$store.getters.isLogged },
    isFormValid () {
      if (this.showEndDateInput && (!this.event.endDateTime || this.event.endDateTime === '')) {
        return false
      }
      if (this.showCriticity && (!this.event.criticity || this.event.criticity === '')) {
        return false
      }
      // End date/time must follow start date/time.
      if (this.showEndDateInput && this.event.endDateTime && this.event.startDateTime && this.$stratus.dt(this.event.endDateTime).isBefore(this.$stratus.dt(this.event.startDateTime))) {
        return false
      }
      return !!this.event.service && !!this.event.description.length > 0 && !!this.event.startDateTime
    },
    services () {
      return this.$store.state.services.list.map(s => s.name) || []
    },
    showCriticity () {
      return this.event.type === 'incident'
    },
    showDurationInput () {
      return this.event.type === 'maintenance'
    },
    showEndDateInput () {
      return this.event.status === 'done'
    },
    statuses () {
      switch (this.event.type) {
        case 'maintenance':
          return ['scheduled', 'in_progress', 'done', 'pause']
        default:
          return ['analysis', 'identified', 'in_progress', 'done']
      }
    }
  },
  methods: {
    async reset () {
      await this.$refs.formEvent.reset()
    },
    save () {
      if (!this.isLogged) {
        return
      }

      if (!this.$refs.formEvent.validate()) {
        this.$stratus.services.notify.warning(this.$t('One or more fields must be corrected!'))
        return
      }

      if (!this.isFormValid) {
        this.$stratus.services.notify.warning(this.$t('One or more fields must be corrected!'))
        return
      }

      this.isSaving = true
      this.$store.dispatch('events/save', {
        eventId: this.eventId || null,
        event: {
          category: this.event.service,
          type: this.event.type,
          status: this.event.status,
          description: this.event.description,
          ...(this.showCriticity && this.event.criticity) ? { criticity: this.event.criticity } : {},
          startTime: this.event.startDateTime,
          ...(this.showEndDateInput && this.event.endDateTime) ? { endTime: this.event.endDateTime } : { endTime: null },
          ...(this.showDurationInput && this.event.duration) ? { duration: this.event.duration } : { duration: null }
        }
      })
        .then(() => {
          this.$stratus.services.notify.success(this.$t('Event saved.'))
          this.$emit('saved')
          return this.$store.dispatch('services/get')
        })
        .catch(error => {
          this.$stratus.services.notify.error(error)
        })
        .finally(() => {
          this.isSaving = false
        })
    }
  }
}
</script>
