<template>
  <v-row dense align="center">
    <v-col>
      <v-text-field :value="defaultDuration.value" @input="changeValue" :label="$t('Duration')" :rules="[$stratus.services.form.rules.integermin(0), $stratus.services.form.rules.integermax(3600)]" />
    </v-col>
    <v-col>
      <v-select :items="units" v-model="defaultDuration.unit" class="pl-3" />
    </v-col>
  </v-row>
</template>

<script>
const DEFAULT_VALUE = {
  value: 10,
  unit: 'm'
}

export default {
  props: {
    duration: {
      type: Object,
      default: () => { return { ...DEFAULT_VALUE } }
    }
  },
  model: {
    prop: 'duration',
    event: 'change'
  },
  data () {
    return {
      units: [
        { value: 's', text: this.$t('Seconds') },
        { value: 'm', text: this.$t('Minutes') },
        { value: 'h', text: this.$t('Hours') }
      ]
    }
  },
  methods: {
    changeValue (value) {
      this.$emit('change', { ...this.defaultDuration, value })
    }
  },
  computed: {
    defaultDuration () {
      return this.duration || DEFAULT_VALUE
    }
  },
  created () {
    if (!this.duration) {
      this.$emit('change', DEFAULT_VALUE)
    }
  }
}
</script>
