<template>
  <div>
    <srs-events-list ref="list" @delete="eventDeleted()" />
    <srs-new-event-form @create="eventCreated()" />
  </div>
</template>

<script>
import EventsList from '@/components/srs/EventsList'
import NewEventForm from '@/components/srs/NewEventForm'

export default {
  components: {
    'srs-events-list': EventsList,
    'srs-new-event-form': NewEventForm
  },
  methods: {
    eventCreated () {
      this.$refs.list.refresh()
    },
    eventDeleted () {
      this.$refs.list.refresh()
    }
  },
  async created () {
    this.$store.commit('$stratus-states/setPageTitle', this.$i18n.t('Home'))
    await this.$store.dispatch('isLogged')
  }
}
</script>
