export default function (options) {
  if (!options || typeof options.callback !== 'function') {
    return
  }

  console.log('[kc] registered.')

  const K_KEYS = [38, 38, 40, 40, 37, 39, 37, 39, 66, 65]
  let pos = 0

  document.addEventListener('keydown', function (event) {
    if (event.keyCode === K_KEYS[pos++]) {
      if (K_KEYS.length === pos) {
        options.callback()
        pos = 0 // ability to start over
        return false
      }
    } else {
      pos = 0
    }
  }, false)
}
