export const DEFAULT_EVENT = {
  id: '',
  service: null,
  type: 'incident',
  status: 'in_progress',
  description: '',
  criticity: null,
  startDateTime: null,
  endDateTime: null,
  duration: { value: 10, unit: 'm' }
}
