import actions from './actions'
import mutations from './mutations'

export default {
  namespaced: true,
  state: {
    list: []
  },
  getters: {
    list: state => state.list
  },
  mutations,
  actions
}
