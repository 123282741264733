import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'

import App from './App.vue'
import { registerRouteCheck, router } from './router/'

import config from '@/config'

import csServices from '@/services'
import locales from '@/locales/'
import VueResource from 'vue-resource'

function init (Vue, vuetify, store, Stratus, Alto) {
  const { name, version, isValid } = Stratus.services.browser.checkBrowser()

  if (isValid && config.sentryDSN && config.sentryDSN.length) {
    Sentry.init({
      dsn: config.sentryDSN,
      environment: config.env,
      integrations: [new Integrations.Vue({ Vue, attachProps: true })],
      ignoreErrors: ['NavigationDuplicated', 'requestAnimationFrame', 'Non-Error promise rejection captured'],
      // Ignore "UnhandledRejection: Non-Error promise rejection captured with value: Timeout"
      // This issue comes from [recaptcha bug](https://github.com/getsentry/sentry-javascript/issues/2514)
      beforeSend (event, hint) {
        if (hint.originalException === 'Timeout') {
          console.log('[Sentry] ignoring "UnhandledRejection: Non-Error promise rejection captured with value: Timeout"')
          return null
        }
        return event
      }
    })
    Stratus.services.debug.log('%c Sentry %c activated! %c')
    Stratus.services.debug.info(`Sentry DSN: %c ${config.sentryDSN} %c`)
  } else {
    Stratus.services.debug.warn('Sentry %c disabled! %c')
    if (!isValid) Stratus.services.debug.warn(`This browser (${name} ${version}) is too old or %c not supported %c. This application will not work properly!`)
  }

  Vue.use(Stratus, {
    api: config.api,
    defaultLocale: config.locale,
    messages: locales.messages,
    LOCALES: locales.LOCALES,
    LOCALES_COUNTRIES: locales.LOCALES_COUNTRIES
  })

  Vue.use(VueResource)
  Vue.use(csServices)

  new Vue({
    i18n: Alto.i18n,
    router,
    store,
    vuetify,
    csServices,
    created () {
      // Update framework modules with ready to all-inclusive translations
      this.$stratus.services.array.register(Alto.i18n, this.$stratus.dt)
      // Allow frameworks access from store
      this.$store.commit('loadStratus')
      this.$store.commit('loadAlto')
      this.$stratus.services.debug.info(`Stratus version: %c ${Stratus.version} %c`)
      this.$stratus.services.debug.info(`Alto version: %c ${Alto.version} %c`)
      registerRouteCheck(this.$stratus, router)
      // Register HTTP interceptors
      this.$stratus.services.http = Vue.use(this.$stratus.services.http, this.$stratus.services.auth)
    },
    render: h => h(App)
  }).$mount('#app')
}

export default { init }
