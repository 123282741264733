import config from '@/config'

/**
 * Return a route ready for use in vue-router.
 * @param  {String} path            URI path
 * @param  {Object} component       Full path to component component (.vue) that must be store in src/**
 * @param  {String} [name]          Name of the route to create
 * @param  {Object} [children[]]    Array of children routes
 * @return {Object}                 A route to add to property routes of vue-router instance
 */
function loadRoute (path, component, name, children) {
  if (component.indexOf('.vue') < 0) {
    component += '.vue'
  }

  if (config.debug.router) {
    console.log('[loadRoute]', `${path} (${name}) -> @/${component}`)
  }

  return {
    exact: true,
    path,
    name,
    children,
    // NOTE: vue-loader change how it load vue modules!
    // Read https://github.com/vuejs/vue-loader/releases/tag/v13.0.0 breaking change.
    // component: require(`@/${component}`) // For vue-loader < 13.0 (load modules as CommonJS modules)
    component: require(`@/${component}`).default // For vue-loader >= 13.0 (load modules as ES modules)
  }
}

/**
 * Return a route ready for use in vue-router.
 * @param  {String} path            URI path
 * @param  {Object} component       Full path to component component (.vue) that must be store in src/**
 * @param  {String} [name]          Name of the route to create
 * @param  {Object} [children[]]    Array of children routes
 * @return {Object}                 A route to add to property routes of vue-router instance
 */
function loadViewRoute (path, component, name, children) {
  return loadRoute(path, 'views/' + component, name, children)
}

/**
 * Return a route ready for use in vue-router.
 * @param  {String} path            URI path
 * @param  {Object} component       Full path to component component (.vue) that must be store in src/**
 * @param  {String} [name]          Name of the route to create
 * @param  {Object} [children[]]    Array of children routes
 * @return {Object}                 A route to add to property routes of vue-router instance
 */
function loadComponentRoute (path, component, name, children) {
  return loadRoute(path, 'components/' + component, name, children)
}

/**
 * Return a route for using a custom form to create record.
 * @method loadCustomFormFromRouteCreate
 * @param  {String} resource [description]
 * @param  {String} component [description]
 * @return {Object} A route to add to property routes of vue-router instance
 * @example
 * loadCustomFormRouteForCreate('users', 'Wizard') // This will create a route '/crud/custom/users/Wizard/create', with name 'users/Wizard/create' that link to component @/src/components/users/wizard.vue
 */
function loadCustomFormRouteForCreate (resource, component) {
  return loadComponentRoute(`/crud/custom/${resource}/create`, `${resource}/${component}`, `${resource}/${component}/create`)
}

/**
* Return a route for using a custom form to update a record.
* @method loadCustomFormFromRouteCreate
* @param  {String} resource [description]
* @param  {String} component [description]
* @return {Object} A route to add to property routes of vue-router instance
* @example
* loadCustomFormRouteForUpdate('users', 'Wizard') // This will create a route '/crud/custom/users/Wizard/update', with name 'users/Wizard/update' that link to component @/src/components/users/wizard.vue
*/
function loadCustomFormRouteForUpdate (resource, component) {
  return loadComponentRoute(`/crud/custom/${resource}/update`, `${resource}/${component}`, `${resource}/${component}/update`)
}

/*
* Route to a special component for a page not found. It goes to 404.html
*/
function pageNotFound () {
  return loadRoute('*', 'views/PageNotFound', 'pagenotfound')
}

export default {
  loadComponentRoute,
  loadCustomFormRouteForCreate,
  loadCustomFormRouteForUpdate,
  loadViewRoute,
  loadRoute,
  pageNotFound
}
