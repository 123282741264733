<template>
  <v-app :dark="dark" standalone>
    <v-navigation-drawer v-model="drawer" app :width="320" stateless disable-resize-watcher>
      <div class="pa-2 d-flex">
        <v-img :src="`/img/brand-2024-${dark ? 'dark' : 'light'}.svg?1`" :max-width="200" :height="56" />
        <div class="ml-auto">
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" @click.native.stop="drawer = !drawer" class="clickable menu-icon--text" :class="drawer ? 'rotate-once-180' : ''">
                $vuetify.icons.right
              </v-icon>
            </template>
            <span>{{ drawer ? $t('Expand') : $t('Minimize') }}</span>
          </v-tooltip>
        </div>
      </div>

      <sca-app-menu :menu="menu" :locale="currentLocale" @localeChange="changeLocale" @contrastChange="toggleDark" brand-website contrast can-login :external-links="externalLinks" @login="onLogin" @logout="onLogout" />
    </v-navigation-drawer>

    <v-slide-y-transition>
      <v-app-bar fixed flat app>
        <v-icon v-show="!drawer" dark @click="drawer = !drawer" class="menu-icon--text pr-2">
          $vuetify.icons.right
        </v-icon>

        <v-toolbar flat class="transparent">
          <v-toolbar-title>
            <router-link to="/">
              <span :class="dark ? 'white--text' : 'black--text'">{{ $t('Scalair Status') }}</span>
            </router-link>

            <span class="text-subtitle-1">
              {{ pageTitle ? ' — ' + pageTitle : '' }}
            </span>
          </v-toolbar-title>

          <v-spacer />

          <v-scroll-y-transition>
            <div v-if="config?.api && feedsInHeader" class="ml-auto">
              <srs-feeds-menu :label="$t('Feeds')" />
            </div>
          </v-scroll-y-transition>
        </v-toolbar>
      </v-app-bar>
    </v-slide-y-transition>

    <v-main class="app-main-container">
      <div :class="$vuetify.breakpoint.mdAndUp ? 'pt-3 px-4 mb-5' : ''" mt-4>
        <v-row v-if="permanentError" align="center" class="pa-2 danger white--text" no-gutters>
          <v-col class="shrink pr-4">
            <v-icon x-large color="white">
              $vuetify.icons.warning
            </v-icon>
          </v-col>
          <v-col class="text-h6">
            {{ permanentError }}
          </v-col>
        </v-row>

        <srs-infra-status />

        <v-scroll-y-reverse-transition>
          <div v-if="!feedsInHeader" class="mt-4 d-flex justify-center">
            <div class="flex-shrink-1">
              <cs-alert-panel dismiss @dismiss="feedsInHeader = true">
                <template #content>
                  <srs-feeds-menu :label="$t('Stay up-to-date with our feeds')" />
                </template>
              </cs-alert-panel>
            </div>
          </div>
        </v-scroll-y-reverse-transition>

        <v-slide-y-transition mode="out-in">
          <router-view />
        </v-slide-y-transition>
      </div>
    </v-main>

    <notifications group="cs-notifications" position="top center" :width="$vuetify.breakpoint.smAndDown ? '100%' : '50%'" />

    <kc ref="kc" />
  </v-app>
</template>

<script>
import config from '../config'
import InfraStatus from '@/components/srs/InfraStatus'
import FeedsMenu from '@/components/srs/FeedsMenu'
import kc from '@/services/kc'
import KC from './kc'
import { mapState } from 'vuex'

export default {
  components: {
    'srs-infra-status': InfraStatus,
    'srs-feeds-menu': FeedsMenu,
    kc: KC
  },
  data () {
    return {
      config,
      dark: false,
      feedsInHeader: false,
      me: {},
      drawer: false,
      currentLocale: null,
      permanentError: ''
    }
  },
  computed: {
    ...mapState(['message', 'menu']),
    pageTitle () { return this.$store.getters['$stratus-states/pageTitle'] },
    externalLinks () {
      return [
        {
          help: this.$t('Feeds'),
          icon: '$vuetify.icons.feed',
          title: this.$t('List of RSS feeds'),
          items: [
            { href: `${config.api}/events/feeds?limit=0&feed_content=incident&feed_type=rss`, title: this.$t('Incidents') },
            { href: `${config.api}/events/feeds?limit=0&feed_content=maintenance&feed_type=rss`, title: this.$t('Maintenance') },
            { href: `${config.api}/events/feeds?limit=0&feed_content=all&feed_type=rss`, title: this.$t('All events') }
          ]
        }
      ]
    },
    isLogged () { return this.$store.getters.isLogged }
  },
  methods: {
    loadDatas () {
      this.$store.dispatch('services/get')
      this.$store.dispatch('events/getFloating')
    },
    onLogin (error) {
      if (error) {
        console.error(error)
        this.$stratus.services.notify.error(this.$i18n.t('Can not connect to Scalair Status!'))
        return
      }
      this.$stratus.services.notify.success(this.$i18n.t('You are now connected to Scalair Status!'))
      window.location.reload()
    },
    onLogout (reason) {
      if (reason) this.$stratus.services.notify.warning(reason)
      setTimeout(() => {
        this.$router.push('/').catch((error) => { console.warn(error) })
        window.location.reload()
      }, 500)
    },
    changeLocale (newLocale) {
      this.currentLocale = newLocale
      this.$stratus.services.localStorage.set('locale', newLocale)
      this.$i18n.locale = newLocale
      this.$stratus.dt.locale(newLocale)
      this.$vuetify.lang.current = newLocale
      this.$root.$i18n.locale = this.$store.getters['$stratus-states/locale']
      this.$i18n.locale = this.$store.getters['$stratus-states/locale']
    },
    toggleDark () {
      this.dark = this.$store.getters['$stratus-states/isDark']
      this.$vuetify.theme.dark = this.dark
    }
  },
  async created () {
    document.title = this.$i18n.t('Scalair Status')
    this.changeLocale(this.$stratus.services.localStorage.get('locale') || this.$stratus.defines.i18n.DEFAULT_LOCALE)
    console.log('Locale is', this.$stratus.services.localStorage.get('locale'))
    console.log('Date format is', this.$stratus.dt().format('LL LTS'))
    this.dark = this.$store.getters['$stratus-states/isDark']
    this.$vuetify.theme.dark = this.dark
  },
  async mounted () {
    // await this.$store.dispatch('isLogged')
    this.$root.kc = this.$refs.kc
    this.loadDatas()

    const self = this
    kc({ callback: () => { self.$root.kc.show() } })

    const { name, version, isValid } = this.$stratus.services.browser.checkBrowser()
    if (!isValid) {
      this.permanentError = this.$t('This browser ({name} {version}) is too old or not supported. This application will not work properly!', { name, version })
    }
  }
}
</script>
