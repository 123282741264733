<template>
  <v-row class="pt-3 fill-height">
    <v-btn rounded class="main-button" to="/">
      <v-icon>
        icon-small-arrow-left
      </v-icon>
      {{ $t('Back') }}
    </v-btn>

    <v-col v-if="!event" cols="12">
      <p class="text-h4 text-center">
        {{ $t('Event not found!') }}
      </p>
    </v-col>

    <v-col v-else cols="12">
      <v-row dense>
        <v-col cols="12" lg="7">
          <v-card outlined tile class="px-2 background-blurred">
            <div class="d-flex align-center">
              <v-btn v-if="isLogged && isEditing" icon @click="toggleEventEdition()">
                <v-icon>
                  icon-close
                </v-icon>
              </v-btn>

              <span class="text-h6 mx-4">{{ $t('Event detail') }}</span>

              <v-btn v-if="isLogged && !isEditing" icon @click="toggleEventEdition()">
                <v-icon>
                  icon-edit
                </v-icon>
              </v-btn>

              <v-btn v-if="isLogged && !isEditing" icon @click="deleteEvent()">
                <v-icon class="danger--text">
                  $vuetify.icons.delete
                </v-icon>
              </v-btn>

              <v-btn v-if="isEditing && isLogged" rounded class="main-button ml-auto" @click="saveEvent">
                {{ $t('Save') }}
              </v-btn>
            </div>

            <div v-if="isEditing && isLogged">
              <srs-event-editor ref="event-detail-editor" :event-id="event.id" :event="form" @saved="onSaved" />
            </div>

            <v-card-text v-else>
              <v-row>
                <v-col cols="6">
                  <span class="font-weight-bold">{{ $t('Category') }}</span>
                </v-col>
                <v-col class="shrink">
                  <v-icon :color="getCriticityStyle(event.criticity).class">
                    {{ getEventCategoryIcon(event.category) }}
                  </v-icon>
                </v-col>
                <v-col cols="5" align-self="center" class="px-0">
                  {{ $t(`service-name-${event.category}`) }}
                </v-col>

                <v-col cols="6">
                  <span class="font-weight-bold">{{ $t('Type') }}</span>
                </v-col>
                <v-col cols="6">
                  {{ $t(`event-type-${event.type}`) }}
                </v-col>

                <v-col cols="6">
                  <span class="font-weight-bold">{{ $t('Status') }}</span>
                </v-col>
                <v-col cols="6">
                  {{ $t(`event-status-${event.status}`) }}
                </v-col>

                <v-col cols="6" v-if="event.duration && event.type !== 'incident'">
                  <span class="font-weight-bold">{{ $t('Expected duration') }}</span>
                </v-col>
                <v-col cols="6" v-if="event.duration && event.type !== 'incident'">
                  {{ humanizeDuration(event.duration) }}
                </v-col>

                <v-col cols="6">
                  <span class="font-weight-bold">{{ $t('Start date') }}</span>
                </v-col>
                <v-col cols="6">
                  {{ formatTime(event.startTime) }}
                </v-col>

                <v-col cols="6" v-if="event.endTime">
                  <span class="font-weight-bold">{{ $t('End date') }}</span>
                </v-col>
                <v-col cols="6" v-if="event.endTime">
                  {{ formatTime(event.endTime) }}
                </v-col>

                <v-col cols="6" v-if="event.criticity">
                  <span class="font-weight-bold">{{ $t('Criticality') }}</span>
                </v-col>
                <v-col class="shrink" v-if="event.criticity">
                  <v-icon :color="getCriticityStyle(event.criticity).class">
                    {{ getCriticityStyle(event.criticity).icon }}
                  </v-icon>
                </v-col>
                <v-col cols="5" v-if="event.criticity" align-self="center" class="px-0">
                  {{ $t(`severity-${event.criticity}`) }}
                </v-col>
              </v-row>

              <div class="mt-3 text-subtitle-1" v-html="event.description" />
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" lg="5">
          <v-card outlined tile class="pa-2 background-blurred">
            <div>
              <span class="text-h6 mr-2">{{ $t('Comments') }}</span>
              <v-chip>
                {{ event.comments.length }}
              </v-chip>
            </div>

            <v-row v-if="isLogged" class="mt-2 px-4">
              <v-col cols="12">
                <cs-wysiwyg-textarea v-if="isLogged" v-model="content" :placeholder="$t('Here the latest news on interruption...')" :actions="['bold', 'underline', 'italic' ]" :min-length="1" :max-length="64000" dense />
              </v-col>

              <v-col cols="12" class="text-right">
                <v-btn rounded color="main-button" :disabled="!content.length" @click="submitComment()">
                  {{ $t('Add comment') }}
                </v-btn>
              </v-col>
            </v-row>

            <v-row dense class="mx-2">
              <v-col cols="12" v-for="(comment, index) in event.comments" :key="index">
                <div v-if="index === 0 || showAllComments">
                  <span class="font-weight-bold text-uppercase">{{ comment.user }}</span>

                  <span class="mx-3 text-right">{{ formatTime(comment.createdAt) }}</span>

                  <v-btn v-if="isLogged" rounded icon small color="danger" :loading="false" @click="deleteComment(comment.id)">
                    <v-icon :disabled="!comment.id" class="danger--text" small>
                      $vuetify.icons.delete
                    </v-icon>
                  </v-btn>

                  <p v-html="$stratus.services.strings.stripHtmlTags(comment.content)" class="text-subtitle-1" />
                </div>

                <v-btn v-if="event.comments.length > 1 && index === 0 && !showAllComments" color="main-button" small rounded @click="toggleCommentsDisplay">
                  {{ $t('More comments...') }}
                </v-btn>
                <v-btn v-if="event.comments.length > 1 && index === 0 && showAllComments" color="main-button" small rounded @click="toggleCommentsDisplay">
                  {{ $t('Hide comments') }}
                </v-btn>
                <v-divider v-if="index < event.comments.length - 1 && showAllComments" :key="`divider-${index}`" class="my-2" />
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-col>

    <cs-confirm-dialog ref="eventDeletionDialog" />
    <cs-confirm-dialog ref="eventCommentDeletionDialog" />
  </v-row>
</template>

<script>
import EventEditor from '@/components/srs/EventEditor'
import { getEventCategoryIcon, getCriticityStyle, criticities } from '@/services/services'

export default {
  components: {
    'srs-event-editor': EventEditor
  },
  data () {
    return {
      criticities,
      getEventCategoryIcon,
      getCriticityStyle,
      showAllComments: false,
      isEditing: false,
      icons: {
        service: {
          compute: 'memory',
          storage: 'storage',
          network: 'settings_ethernet',
          voip: 'local_phone',
          other: 'toll'
        }
      },
      content: '',
      form: {
        service: this.event ? this.event.category : '',
        type: this.event ? this.event.type : '',
        status: this.event ? this.event.status : '',
        description: this.event ? this.event.description : '',
        criticity: this.event ? this.event.criticity : '',
        startDateTime: this.event ? this.event.startTime : '',
        endDateTime: this.event ? this.event.endTime : '',
        duration: this.event ? this.event.duration : ''
      }
    }
  },
  computed: {
    dark () { return this.$store.getters.dark },
    services () {
      return this.$store.state.services.list.map(s => s.name)
    },
    statuses () {
      switch (this.form.type) {
        case 'maintenance':
          return ['scheduled', 'in_progress', 'done', 'pause']
        default:
          return ['analysis', 'identified', 'in_progress', 'done']
      }
    },
    defaultStatus () {
      switch (this.form.type) {
        case 'maintenance':
          return 'in_progress'
        default:
          return 'analysis'
      }
    },
    event: function () {
      return this.$store.state.events.current
    },
    showCriticity () {
      return this.form.type === 'incident'
    },
    isLogged () { return this.$store.getters.isLogged },
    isFormValid () {
      if (this.showEndDateInput && (!this.form.endDateTime || this.form.endDateTime === '')) {
        return false
      }
      if (this.showCriticity && (!this.form.criticity || this.form.criticity === '')) {
        return false
      }
      return !!this.form.service && !!this.form.description.length > 0 && !!this.form.startDateTime
    }
  },
  watch: {
    event () {
      this.form = {
        service: this.event ? this.event.category : '',
        type: this.event ? this.event.type : '',
        status: this.event ? this.event.status : '',
        description: this.event ? this.event.description : '',
        criticity: this.event ? this.event.criticity : '',
        startDateTime: this.event ? this.event.startTime : '',
        endDateTime: this.event ? this.event.endTime || '' : '',
        duration: this.event ? this.event.duration : ''
      }
    }
  },
  methods: {
    loadDatas () {
      if (this.$route.params.id) {
        this.$store.dispatch('events/getCurrentEvent', this.$route.params.id)
      }
    },
    humanizeDuration (duration) {
      const parsedDuration = this.$stratus.dt.duration(duration.value, duration.unit)
      return this.$stratus.services.strings.capitalize(parsedDuration.humanize())
    },
    formatTime (time) {
      return this.$stratus.dt(time).format('LLLL')
    },
    onSaved () {
      this.isEditing = false
    },
    saveEvent () {
      this.$refs['event-detail-editor'].save()
    },
    toggleEventEdition () {
      this.isEditing = !this.isEditing
    },
    toggleCommentsDisplay () {
      this.showAllComments = !this.showAllComments
    },
    async deleteEvent () {
      if (!this.isLogged) {
        return
      }

      const confirm = await this.$refs.eventDeletionDialog.open(
        this.$t('Delete'),
        'Confirmez-vous la suppression de cette interruption ?',
        { color: 'red' }
      )
      if (!confirm) {
        return
      }

      await this.$store.dispatch('events/deleteEvent', this.event.id)
      await this.$store.dispatch('services/get')
      this.$router.push('/').catch((error) => { console.warn(error) })
    },
    async deleteComment (commentId) {
      if (!this.isLogged) {
        return
      }

      const confirm = await this.$refs.eventCommentDeletionDialog.open(
        this.$t('Delete'),
        'Confirmez-vous la suppression de ce commentaire ?',
        { color: 'red' }
      )
      if (!confirm) {
        return
      }

      const eventId = this.event.id
      await this.$store.dispatch('events/deleteEventComment', { eventId, commentId })
    },
    async submitComment () {
      if (!this.isLogged) {
        return
      }

      const eventId = this.event.id
      await this.$store.dispatch('events/addEventComment', { eventId, content: this.content })
      this.content = ''
    }
  },
  async mounted () {
    this.loadDatas()
  }
}
</script>
