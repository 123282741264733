const baseUrl = process.env.VUE_APP_API_URL || 'http://api.status.scalair.localhost'
const appVersion = '<local>'

const config = {
  env: 'devlopment',
  api: baseUrl,
  appVersion,
  url: baseUrl,
  locale: 'fr-FR',
  avatarApi: 'https://www.gravatar.com/avatar/<%=hash%>?s=64&d=retro',
  romaApi: process.env.VUE_APP_API_URL_ROMA || 'http://api.scalair.localhost',
  debug: {
    i18n: true, // Log warning if translation is missing
    router: true, // Display log for route loading
    http: true // Log every HTTP request
  }
}

export default config
