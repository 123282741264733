import actions from './actions'
import mutations from './mutations'

export default {
  namespaced: true,
  state: {
    current: null,
    eventsByDate: null,
    floatingEvents: null
  },
  getters: {
    eventsByDate: state => { return state.eventsByDate || [] },
    floatingEvents: state => { return state.floatingEvents || [] }
  },
  mutations,
  actions
}
