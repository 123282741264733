const baseUrl = '{{ API_URL }}'
const sentryDSN = '{{ SENTRY_DSN }}'
const appVersion = '{{ APP_VERSION }}'

const config = {
  env: 'production',
  appVersion,
  url: baseUrl,
  api: baseUrl,
  sentryDSN,
  locale: 'fr-FR',
  avatarApi: 'https://www.gravatar.com/avatar/<%=hash%>?s=64&d=retro',
  debug: {
    i18n: false, // Log warning if translation is missing
    router: false, // Display log for route loading
    http: false // Log every HTTP request
  }
}

export default config
