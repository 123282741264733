import Vue from 'vue'
import all from './all'

// Register services
Vue.mixin({
  beforeCreate () {
    const options = this.$options
    if (options.csServices) {
      this.$csServices = options.csServices
    } else if (options.parent && options.parent.$csServices) {
      this.$csServices = options.parent.$csServices
    }
  }
})

export default all
