import { getEventCategoryIcon, getStyle, getCriticityStyle } from '@/services/services'

export default {
  setServices (state, { rootState, services }) {
    const serv = services
    serv.forEach(function (s) {
      s.icon = getEventCategoryIcon(s.name)
      s.createdAt = rootState.$stratus.dt(s.createdAt)
      s.updatedAt = rootState.$stratus.dt(s.updatedAt)
      // Event criticiality = medium --> service status is set to KO — WTF?
      if (s.currentEvent) {
        s.statusStyle = getCriticityStyle(s.currentEvent.criticity || 'medium')
        s.status = rootState.$stratus.i18n.t('event-type-' + s.currentEvent.type.toLowerCase())
      } else {
        s.statusStyle = getStyle(s.status)
        s.status = rootState.$stratus.i18n.t('service-status-' + s.status.toLowerCase())
      }
    })
    state.list = serv
  }
}
