export default {
  'Add comment': 'Ajouter un commentaire',
  'Affected service': 'Service impacté',
  'Back to history': 'Retour à l\'historique',
  'Can not connect to Scalair Status!': 'Erreur de connexion au portail Scalair !',
  'Connection: ': 'Connexion : ',
  'Criticity / duration': 'Criticité / durée',
  'Describe the interruption...': 'Description de l\'interruption…',
  'End date': 'Date de fin',
  'End time': 'Heure de fin',
  'Event detail': 'Détails de l\'événement',
  'Event not found!': 'Événement non trouvé !',
  'Event saved.': 'Événement enregistré.',
  'Event type': 'Type d\'interruption',
  'event-status-analysis': 'En cours d\'analyse…',
  'event-status-closed': 'Terminé',
  'event-status-done': 'Terminé',
  'event-status-identified': 'Cause identifiée',
  'event-status-in_progress': 'En cours…',
  'event-status-pause': 'En pause',
  'event-status-scheduled': 'Planifié',
  'event-type-incident': 'Incident',
  'event-type-maintenance': 'Maintenance',
  Incident: 'Incident',
  Maintenance: 'Maintenance',
  'Expected duration': 'Durée prévue',
  'Hide comments': 'Masquer les commentaires',
  'Last update': 'Mise à jour',
  'More comments...': 'Plus de commentaires…',
  'New service outage': 'Nouvelle interruption de service',
  'No recent event to display. All services up and running.': 'Pas d\'événement récent à afficher. Tous les services fonctionnent.',
  'Outage type': 'Type d\'interruption',
  'Pending...': 'En cours…',
  'Scalair Applications': 'Applications Scalair',
  'Scalair Status': 'États des services Scalair',
  'service-name-compute': 'Calcul',
  'service-name-network': 'Réseau',
  'service-name-other': 'Autre',
  'service-name-storage': 'Stockage',
  'service-name-voip': 'VoIP',
  'service-status-ko': 'KO',
  'service-status-ok': 'OK',
  'severity-high': 'Élevée',
  'severity-low': 'Basse',
  'severity-medium': 'Moyenne',
  'Start date': 'Date de début',
  'Start time': 'Heure de début',
  'Works and incidents': 'Travaux et incidents',
  'You are now connected to Scalair Status!': 'Vous êtes connecté au portail Scalair !',
  About: 'À propos',
  Back: 'Retour',
  Cancel: 'Annuler',
  Category: 'Catégorie',
  Comments: 'Commentaires',
  Criticality: 'Criticité',
  Description: 'Description',
  Duration: 'Durée',
  History: 'Historique',
  Home: 'Accueil',
  Hours: 'Heures',
  Minutes: 'Minutes',
  No: 'Non',
  Period: 'Période',
  Save: 'Enregistrer',
  Seconds: 'Secondes',
  Status: 'Statut',
  Timeline: 'Chronologie',
  Type: 'Type',
  Uptime: 'Uptime',
  Yes: 'Oui',
  'Last comment': 'Dernier commentaire',
  'Here the latest news on interruption...': 'Ici les dernières nouvelles sur l\'interruption…',
  '(formatting included)': '(mise en forme incluse)',
  Feeds: 'Flux',
  'List of RSS feeds': 'Liste des flux RSS',
  Incidents: 'Incidents',
  'All events': 'Tous les événements',
  'Stay up-to-date with our feeds': 'Restez au courant des incidents et des maintenances grâce à nos flux RSS'
}
