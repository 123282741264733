export default {
  'Add comment': 'Add comment',
  'Affected service': 'Affected service',
  'Back to history': 'Back to history',
  'Can not connect to Scalair Status!': 'Can not connect to Scalair Status!',
  'Connection: ': 'Connection: ',
  'Criticity / duration': 'Criticity / duration',
  'Describe the interruption...': 'Describe the interruption...',
  'End date': 'End date',
  'End time': 'End time',
  'Event detail': 'Event detail',
  'Event not found!': 'Event not found!',
  'Event saved.': 'Event saved.',
  'Campaign type': 'Campaign type',
  'event-status-analysis': 'Analysing...',
  'event-status-closed': 'Done',
  'event-status-done': 'Done',
  'event-status-identified': 'Outage cause identified',
  'event-status-in_progress': 'In progress...',
  'event-status-pause': 'Pause',
  'event-status-scheduled': 'Scheduled',
  'event-type-incident': 'Incident',
  'event-type-maintenance': 'Maintenance',
  Incident: 'Incident',
  Maintenance: 'Maintenance',
  'Expected duration': 'Expected duration',
  'Hide comments': 'Hide comments',
  'Last update': 'Last update',
  'More comments...': 'More comments...',
  'New service outage': 'New service outage',
  'No recent event to display. All services up and running.': 'No recent event to display. All services up and running.',
  'Outage type': 'Outage type',
  'Pending...': 'Pending...',
  'Scalair Applications': 'Scalair Applications',
  'Scalair Status': 'Scalair services status',
  'service-name-compute': 'Compute',
  'service-name-network': 'Network',
  'service-name-other': 'Other',
  'service-name-storage': 'Storage',
  'service-name-voip': 'VoIP',
  'service-status-ko': 'KO',
  'service-status-ok': 'OK',
  'severity-high': 'High',
  'severity-low': 'Low',
  'severity-medium': 'Medium',
  'Start date': 'Start date',
  'Start time': 'Start time',
  'Works and incidents': 'Works and incidents',
  'You are now connected to Scalair Status!': 'You are now connected to Scalair Status!',
  Back: 'Back',
  Cancel: 'Cancel',
  Category: 'Category',
  Comments: 'Comments',
  Criticality: 'Criticality',
  Description: 'Description',
  Duration: 'Duration',
  History: 'History',
  Home: 'Home',
  Hours: 'Hours',
  Minutes: 'Minutes',
  No: 'No',
  Period: 'Period',
  Save: 'Save',
  Seconds: 'Seconds',
  Status: 'Status',
  Timeline: 'Timeline',
  Type: 'Type',
  Uptime: 'Uptime',
  Yes: 'Yes',
  'Last comment': 'Last comment',
  'Here the latest news on interruption...': 'Here the latest news on interruption...',
  '(formatting included)': '(formatting included)', // «(mise en forme incluse)»
  Feeds: 'Feeds',
  'List of RSS feeds': 'List of RSS feeds',
  Incidents: 'Incidents',
  'All events': 'All events',
  'Stay up-to-date with our feeds': 'Stay up-to-date with our feeds'
}
