export const criticities = ['high', 'medium', 'low'] // TODO: Export in config file

export function getStyle (status) {
  switch ((status || '').toUpperCase()) {
    case 'OK': return {
      class: 'alert_ok',
      icon: '$vuetify.icons.meteoOK'
    }
    case 'SEVERITY1': return {
      class: 'alert_error',
      icon: '$vuetify.icons.meteoKO'
    }
    case 'SEVERITY2': return {
      class: 'alert_warning',
      icon: '$vuetify.icons.meteoKO'
    }
    case 'KO': return {
      class: 'alert_critical',
      icon: '$vuetify.icons.meteoKO'
    }
    case 'SEVERITY3':
    default: return {
      class: 'alert_unknown',
      icon: '$vuetify.icons.unknown'
    }
  }
}

export function getCriticityStyle (criticity) {
  switch ((criticity || '').toUpperCase()) {
    case 'HIGH': return {
      class: 'alert_error',
      icon: '$vuetify.icons.meteoKO'
    }
    case 'MEDIUM': return {
      class: 'alert_warning',
      icon: '$vuetify.icons.meteoKO'
    }
    case 'LOW':return {
      class: 'alert_unknown',
      icon: '$vuetify.icons.meteoKO'
    }
    default: return {
      class: 'alert_none',
      icon: '$vuetify.icons.setup' // For maintenance
    }
  }
}

export function getEventCategoryIcon (name) {
  switch (name.toUpperCase()) {
    case 'COMPUTE': return 'icon-cpu-filled'
    case 'STORAGE': return 'icon-storage-filled'
    case 'NETWORK': return 'icon-network-filled'
    case 'VOIP': return 'icon-voip-filled'
    case 'OTHER': return 'icon-other-filled'
  }
}
