import _ from 'lodash'
import { DEFAULT_EVENT } from '@/services/events'

// Float duration is 1 month. It tell us to keep events from now to 1 month in the past, date to date.
const FLOAT_DURATION = 1
const FLOAT_DURATION_UNIT = 'month'

export default {
  setCurrentEvent (state, event) {
    // Sanitize event comments
    _.forEach(event?.comments, comment => {
      comment.content = state.$stratus.services.strings.stripHtmlTags(comment.content)
    })
    state.current = Object.assign({}, DEFAULT_EVENT, event)
  },
  setFloating (state, { rootState, events }) {
    const now = rootState.$stratus.dt()
    const from = now.subtract(FLOAT_DURATION, FLOAT_DURATION_UNIT)

    const results = []
    const resultsFuture = []
    // Build an array of days for «now» to «from» dates (order is by date descending))
    const eventsByDate = {}
    let d = now.endOf('day')
    while (d.isAfter(from)) {
      if (!eventsByDate[d]) {
        eventsByDate[d.unix()] = [] // Empty events array
      }
      d = d.subtract(1, 'days')
    }

    // Keep events that falls in the duration
    if (events && events.results) {
      _.forEach(events.results, (event, index) => {
        try {
          event.startTime = rootState.$stratus.dt(event.startTime)
          event.endTime = event.endTime ? rootState.$stratus.dt(event.endTime) : event.endTime
          if (
            // Keep only the events that end in defined duration,
            event.startTime.isAfter(from) ||
            // or event that have not ended yet,
            !event.endTime ||
            // or that will begin in future
            event.endTime.isAfter(from)
          ) {
            if (event.startTime.isAfter(now)) resultsFuture.push(event)
            else results.push(event)

            // Insert event in date array if array contains its date. Some event, like future one, will NOT be inserted into this array
            if (eventsByDate[event.startTime.endOf('day').unix()]) {
              // event has started in the period "from -> now"
              event.byDate = true
              eventsByDate[event.startTime.endOf('day').unix()].push(event)
            } else if (event.endTime && event.endTime.isAfter(from)) {
              // This event has ended in the period "from -> now"
              event.byDate = true
              eventsByDate[event.endTime.endOf('day').unix()].push(event)
            } else {
              // Some event that have started before "from", but that have end date after "from" date
              // They will appear above the timeline
              event.byDate = false
            }
          }
        } catch (error) {
          console.error('[event] Exception for #', index, event)
          console.log(error)
        }
      })
    }
    state.floatingEvents = _.sortBy(resultsFuture, result => { return -result.startTime }).concat(_.sortBy(results, result => { return -result.startTime }))
    state.eventsByDate = eventsByDate
  }
}
