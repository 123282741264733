<template>
  <v-dialog v-model="dialog" :fullscreen="$vuetify.breakpoint.smAndDown" width="75%" scrollable persistent>
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" v-show="isLogged()" absolute fab top right class="main-button mt-4">
        <v-icon>$vuetify.icons.add</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <v-btn icon rounded @click="dialog = false">
          <v-icon>
            icon-close
          </v-icon>
        </v-btn>
        <span class="ml-2 text-h6">{{ $t('New service outage') }}</span>
      </v-card-title>

      <v-card-text>
        <srs-event-editor ref="new-event-editor" :event-id="event.id" :event="event" @saved="onSaved" />
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn rounded class="main-button" @click="saveEvent">
          {{ $t('Save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import EventEditor from '@/components/srs/EventEditor'
import { DEFAULT_EVENT } from '@/services/events.js'

export default {
  components: {
    'srs-event-editor': EventEditor
  },
  data () {
    return {
      showDialog: false,
      event: { ...DEFAULT_EVENT }
    }
  },
  computed: {
    dialog: {
      get () { return this.showDialog },
      set (value) {
        if (value) {
          // Reset values
          this.event = {
            ...DEFAULT_EVENT,
            startDateTime: new Date(),
            endDateTime: new Date()
          }
        }
        this.showDialog = value
      }
    }
  },
  methods: {
    isLogged () { return this.$store.getters.isLogged },
    onSaved () {
      this.$refs['new-event-editor'].reset()
      this.dialog = false
    },
    saveEvent () {
      this.$refs['new-event-editor'].save()
      this.$emit('create')
    }
  }
}
</script>
