import Vue from 'vue'
import Vuex from 'vuex'
import menu from '@/views/menu'
import config from '@/config'
import servicesModule from './modules/services'
import eventsModule from './modules/events'

import pkg from '../../package.json'

Vue.use(Vuex)

const MODULES = {
  services: servicesModule,
  events: eventsModule
}

const store = new Vuex.Store({
  modules: MODULES,
  state: {
    $stratus: null,
    menu,
    me: null, // Me is the user that is connected
    appConfig: config,
    appVersion: pkg.version,
    dark: false,
    isLogged: false
  },
  getters: {
    appConfig: state => {
      return state.appConfig
    },
    appVersion: state => {
      return state.appVersion
    },
    dark: state => {
      state.dark = state.$stratus.services.localStorage.get('dark-theme', false)
      return state.dark
    },
    isLogged: state => { return state.isLogged },
    me: state => {
      return state.me
    }
  },
  actions: {
    getMe ({ commit, state }) {
      return state.$stratus.services.api.get('/me')
        .then(response => {
          commit('setMe', response)
          return state.me
        })
        .catch(error => console.error('!!getMe', error))
    },
    isLogged ({ commit, state }) {
      return state.$stratus.services.api.get('/me')
        .then(response => {
          commit('setIsLogged', response && response.is_lord && state.$stratus.services.auth.isLogged())
        })
        .catch(() => {
          commit('setIsLogged', false)
        })
    }
  },
  mutations: {
    setIsLogged (state, isLogged) {
      state.isLogged = isLogged
    },
    setMe (state, data) {
      state.me = data
    },
    loadAlto (state) {
      state.$alto = this._vm.$alto
      console.log('Linked to Alto framework', state.$alto ? 'Done' : ' Failed!')
    },
    loadStratus (state) {
      function link2Module (modules) {
        Object.keys(modules).forEach((modName) => {
          if (modules[modName].state) {
            modules[modName].state.$stratus = state.$stratus
          }
          console.log('Linked to Stratus framework:', modName)
          if (modules[modName].modules) {
            link2Module(modules[modName].modules)
          }
        })
      }

      state.$stratus = this._vm.$stratus
      link2Module(MODULES)
      console.log('Linked to Stratus framework', state.$stratus ? 'Done' : ' Failed!')
    },
    toggleDark (state) {
      state.$stratus.services.localStorage.set('dark-theme', !state.dark)
      state.dark = !state.dark
    }
  }
})

export default store
