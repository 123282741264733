<template>
  <v-row dense justify="center">
    <v-col cols="12" v-if="!services">
      <v-progress-circular indeterminate />
    </v-col>

    <v-col cols="12" md="" class="pb-1" v-for="(service, index) in services" :key="index">
      <v-card outlined tile class="background-blurred" :class="'pa-1' + (service.currentEvent ? ' clickable' : '')" @click="showEvent(service.currentEvent)">
        <div class="d-flex align-center">
          <v-icon class="pr-1'">
            {{ service.statusStyle.icon }}
          </v-icon>
          {{ service.status }}
        </div>
        <v-card-text class="text-center">
          <p>
            <v-avatar :class="service.statusStyle.class + ' ' + service.statusStyle.class + '_text--text'">
              <v-icon :class="service.statusStyle.class + '_text--text'">
                {{ service.icon }}
              </v-icon>
            </v-avatar>
          </p>
          <span class="text-h6"> {{ $t('service-name-' + service.name) }}</span>
        </v-card-text>

        <v-card-text>
          <p class="text-caption">
            {{ $t('Last update') }} : {{ updatedAt.format('l LTS') }}
          </p>
          <template v-if="isLogged">
            <v-spacer />
            <span>
              {{ $t('Uptime') }} : {{ formatUptime(service.uptime) }}
            </span>
          </template>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'InfraStatus',
  data () {
    return {
      updatedAt: this.$stratus.dt()
    }
  },
  computed: {
    isLogged () { return this.$store.getters.isLogged },
    services () { return this.$store.getters['services/list'] }
  },
  methods: {
    dark () { return this.$store.getters['$stratus-states/isDark'] },
    formatUptime (uptime) {
      if (uptime === -1) {
        // No outage
        return '\u221e' // => '∞' character
      }
      if (uptime === 0) {
        // Outage happening
        return '\u2716' // => '✖' character
      }
      const duration = this.$stratus.dt.duration(uptime, 'ms')
      return this.$stratus.services.strings.capitalize(duration.humanize())
    },
    refreshStatus () {
      this.$store.dispatch('services/get')
      this.updatedAt = this.$stratus.dt()
    },
    showEvent (event) {
      if (event && event.id) {
        this.$router
          .push('/')
          .catch(error => {
            if (error.toString().includes('Avoided redundant navigation to current location')) {
              console.log('Reloading page...')
            } else {
              this.$stratus.services.notify.error(error)
            }
          })

        this.$nextTick(() => {
          this.$router.push('/events/' + event.id).catch((error) => { console.warn(error) })
        })
      }
    }
  },
  mounted () {
    this.refreshStatus()
  }
}
</script>

<style>
.clickable {
  cursor: pointer;
}
</style>
