<template>
  <srs-event-details />
</template>

<script>
import EventDetails from '@/components/srs/EventDetails'

export default {
  components: {
    'srs-event-details': EventDetails
  }
}
</script>
