<template>
  <v-timeline-item color="transparent" class="px-2">
    <template v-slot:icon>
      <v-icon :color="getCriticityStyle(event.criticity).class">
        {{ getEventCategoryIcon(event.category) }}
      </v-icon>
    </template>

    <template v-slot:opposite>
      <span v-if="endTime(event).length">
        {{ formatTime(event.startTime) }}<v-icon small>icon-long-arrow-right</v-icon>{{ endTime(event) }}
      </span>
      <span v-else>
        {{ $t('event-status-scheduled') }}<v-icon small>icon-long-arrow-right</v-icon>{{ formatTime(event.startTime) }}
      </span>
    </template>

    <v-card outlined class="px-2 transparent">
      <cs-expand-panel block>
        <template #header>
          <div class="d-flex align-center">
            <v-chip label :class="'text-h5 ' + (event.type === 'incident' ? getCriticityStyle(event.criticity).class + ' ' + getCriticityStyle(event.criticity).class + '_text--text' : '') ">
              {{ $t(`event-type-${event.type}`) }}
            </v-chip>

            <span class="mx-2">
              [{{ $t(`service-name-${event.category}`) }}]
            </span>

            <v-icon v-if="event.status === 'pause'" small right>
              $vuetify.icons.pause
            </v-icon>

            <v-badge v-if="event.comments.length > 0" :content="event.comments.length" overlap>
              <v-icon small>
                $vuetify.icons.comment
              </v-icon>
            </v-badge>

            <v-btn icon :to="`/events/${event.id}`" class="ml-2">
              <v-icon>
                $vuetify.icons.read
              </v-icon>
            </v-btn>
          </div>
        </template>

        <template #content>
          <v-row dense class="pa-2">
            <v-col cols="6">
              <span class="font-weight-bold">{{ $t('Status') }}</span>
            </v-col>
            <v-col cols="6">
              {{ $t(`event-status-${event.status}`) }}
            </v-col>

            <v-col cols="6" v-if="event.duration && event.type !== 'incident'">
              <span class="font-weight-bold">{{ $t('Expected duration') }}</span>
            </v-col>
            <v-col cols="6" v-if="event.duration && event.type !== 'incident'">
              {{ humanizeDuration(event.duration) }}
            </v-col>

            <v-col cols="6">
              <span class="font-weight-bold">{{ $t('Start date') }}</span>
            </v-col>
            <v-col cols="6">
              {{ formatTime(event.startTime) }}
            </v-col>

            <v-col cols="6" v-if="event.endTime">
              <span class="font-weight-bold">{{ $t('End date') }}</span>
            </v-col>
            <v-col cols="6" v-if="event.endTime">
              {{ formatTime(event.endTime) }}
            </v-col>

            <v-col cols="6" v-if="event.criticity">
              <span class="font-weight-bold">{{ $t('Criticality') }}</span>
            </v-col>
            <v-col class="shrink" v-if="event.criticity">
              <v-icon :color="getCriticityStyle(event.criticity).class">
                {{ getCriticityStyle(event.criticity).icon }}
              </v-icon>
            </v-col>
            <v-col cols="5" v-if="event.criticity" align-self="center" class="px-0">
              {{ $t(`severity-${event.criticity}`) }}
            </v-col>

            <v-col cols="6" v-if="event.comments && event.comments.length">
              <span class="font-weight-bold">{{ $t('Last comment') }}</span>
            </v-col>

            <v-col cols="12" v-if="event.comments && event.comments.length">
              <div class="ml-4" v-html="event.comments[0].content" />
              <span class="text-caption ml-4">
                — {{ event.comments[0].user }} ({{ $stratus.dt(event.comments[0].createdAt).format('LLL') }})
              </span>
            </v-col>
          </v-row>
        </template>
      </cs-expand-panel>
    </v-card>
  </v-timeline-item>
</template>

<script>
import { getEventCategoryIcon, getCriticityStyle } from '@/services/services'

export default {
  name: 'EventTimelineItem',
  props: {
    event: { type: Object, required: true }
  },
  data: () => {
    return {
      getEventCategoryIcon,
      getCriticityStyle
    }
  },
  methods: {
    duration (event) {
      if (!event.duration) {
        return ''
      }
      return this.$stratus.dt.duration(event.duration.value, event.duration.unit).humanize()
    },
    endTime (event) {
      return event.endTime ? this.$stratus.dt(event.endTime).format('LLL') : (this.$stratus.dt().isSameOrAfter(this.$stratus.dt(event.startTime)) ? this.$t('Pending...') : '')
    },
    formatTime (time) {
      return this.$stratus.dt(time).format('LLL')
    },
    humanizeDuration (duration) {
      const parsedDuration = this.$stratus.dt.duration(duration.value, duration.unit)
      return this.$stratus.services.strings.capitalize(parsedDuration.humanize())
    }
  }
}
</script>
