<template>
  <cs-about app-name="status" :api="api" />
</template>

<script>
import config from '@/config'

export default {
  data () {
    return {
      api: config.romaApi + '/changelog?fields=date,text,title,level&sort=-date&services='
    }
  }
}
</script>
