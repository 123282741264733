export default {
  async addEventComment ({ dispatch, rootState }, { eventId, content }) {
    const response = await rootState.$stratus.services.api.post(`/events/${eventId}/comments`, {
      content
    })
    await dispatch('getCurrentEvent', eventId)
    return response
  },
  async deleteEvent ({ rootState }, eventId) {
    await rootState.$stratus.services.api.delete(`/events/${eventId}`)
  },
  async deleteEventComment ({ dispatch, rootState }, { eventId, commentId }) {
    const response = await rootState.$stratus.services.api.delete(`/events/${eventId}/comments/${commentId}`)
    await dispatch('getCurrentEvent', eventId)
    return response
  },
  async getCurrentEvent ({ commit, rootState }, id) {
    if (!id) return
    const response = await rootState.$stratus.services.api.get(`/events/${id}`)
    commit('setCurrentEvent', response)
    return response
  },
  async getFloating ({ commit, state, rootState }) {
    // http://api.status.scalair.localhost/events?format=json&sort=-startTime&limit=25&page=1&fields=type%2Ccriticity%2Cduration%2Ccategory%2Cstatus%2CstartTime%2CendTime
    const response = await rootState.$stratus.services.api.get(rootState.$stratus.services.api.encodeQueryString({ limit: 0, fields: 'type,criticity,duration,category,status,startTime,endTime,comments' }, '/events'))
    commit('setFloating', { rootState, events: response })
    return state.floatingEvents
  },
  save ({ dispatch, rootState }, { eventId, event }) {
    if (event.endTime === null) delete event.endTime
    if (event.duration === null) delete event.duration
    delete event.byDate
    if (event.description !== null) event.description = rootState.$stratus.services.strings.stripHtmlTags(event.description)
    if (eventId) {
      return rootState.$stratus.services.api.put(`/events/${eventId}`, event)
        .then(() => {
          return dispatch('getCurrentEvent', eventId)
        })
        .catch(error => {
          throw new Error(error)
        })
    } else {
      return rootState.$stratus.services.api.post('/events', event)
        .then(() => {})
        .catch(error => {
          throw new Error(error)
        })
    }
  }
}
