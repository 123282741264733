<template>
  <v-row class="pt-1">
    <v-col cols="12">
      <v-card outlined tile class="background-blurred">
        <v-card-title class="pl-3 pb-2 text-h6">
          {{ $t('Works and incidents') }}
          <v-spacer />
          <v-btn icon rounded :disabled="isLoading" @click="refresh">
            <cs-icon-loading v-if="isLoading" />
            <v-icon v-else>
              $vuetify.icons.refresh
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-row class="px-2">
          <v-col>
            <v-tabs hide-slider color="transparent" v-model="tab" show-arrows>
              <cs-validation-tab href="#tab-timeline" class="mx-4" csicon="icon-clock" :label="$t('Timeline')" />
              <cs-validation-tab v-if="isLogged" href="#tab-history" csicon="icon-list" class="mx-4" :label="$t('History')" />
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item value="tab-timeline" eager>
                <v-row v-if="lastEvents.length">
                  <v-col v-if="existsNoByDate" cols="12" class="pb-0">
                    <v-timeline>
                      <template v-for="event in lastEvents">
                        <srs-event-timeline-item v-if="!event.byDate" :event="event" :key="event.id" />
                      </template>
                    </v-timeline>
                  </v-col>

                  <v-col class="text-center" cols="12">
                    <div v-if="existsNoByDate" class="menu-icon--text text-h6 ma-0 pt-1 pb-0">
                      :
                    </div>
                    <div class="menu-icon--text text-h6 ma-0 pt-0 pb-0">
                      :
                    </div>
                  </v-col>

                  <v-col cols="12" class="pt-0">
                    <v-timeline>
                      <template v-for="date in lastEventsKeysByDate">
                        <template v-for="event in lastEventsByDate[date]">
                          <srs-event-timeline-item :event="event" :key="event.id" />
                        </template>

                        <v-timeline-item v-if="lastEventsByDate[date].length === 0" :key="date" :color="isToday($stratus.dt.unix(Number(date))) ? 'primary' : 'transparent'" small>
                          <template v-slot:opposite>
                            <v-card-text class="my-4 py-4">
                              <span v-if="isToday($stratus.dt.unix(Number(date)))" class="text-subtitle-1 primary--text font-weight-bold">
                                {{ $stratus.dt.unix(Number(date)).format('LL') }}
                              </span>
                              <span v-else>
                                {{ $stratus.dt.unix(Number(date)).format('ll') }}
                              </span>
                            </v-card-text>
                          </template>
                        </v-timeline-item>
                      </template>
                    </v-timeline>
                  </v-col>

                  <v-col class="text-center" cols="12">
                    <div class="menu-icon--text text-subtitle-1 ma-0 pt-1 pb-0">
                      :
                    </div>
                  </v-col>
                </v-row>

                <v-row v-else-if="isLoading" class="mt-4">
                  <v-col class="text-center" cols="12">
                    <cs-icon-loading large />
                    <p class="text-h6 mt-4 pt-4">
                      {{ $t('Loading...') }}
                    </p>
                  </v-col>
                </v-row>

                <v-row v-else>
                  <v-col class="text-center mt-8" cols="12">
                    <v-icon x-large class="success--text">
                      $vuetify.icons.complete
                    </v-icon>
                    <p class="text-h6 mt-4 pt-4">
                      {{ $t('No recent event to display. All services up and running.') }}
                    </p>
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item v-if="isLogged" value="tab-history" eager>
                <sca-store-grid ref="eventsGrid" resource="events" :columns="columns" :options="options" @showItem="showEvent" @deleteItem="deleteEvent">
                  <template #item-category="{ item, row }">
                    <v-icon class="severity-icon" :class="getCriticityStyle(row.criticity).class">
                      {{ getEventCategoryIcon(item) }}
                    </v-icon>
                    {{ $t('service-name-' + item) }}
                  </template>

                  <template #item-type="{ itemRaw, row }">
                    <div v-if="itemRaw === 'incident'" class="d-flex align-center">
                      <v-icon small left class="severity-icon" :class="getCriticityStyle(row.criticity).class">
                        {{ getCriticityStyle(row.criticity).icon }}
                      </v-icon>
                      {{ $t(`event-type-${itemRaw}`) }}
                      <v-icon small left right>
                        $vuetify.icons.for
                      </v-icon>
                      {{ $t('severity-' + row.criticity) }}
                    </div>
                    <div v-else class="d-flex align-center">
                      <v-icon small left class="severity-icon" :class="getCriticityStyle(row.criticity).class">
                        {{ getCriticityStyle(row.criticity).icon }}
                      </v-icon>
                      {{ $t(`event-type-${itemRaw}`) }}
                      <template v-if="row.duration">
                        <v-icon small left right>
                          $vuetify.icons.for
                        </v-icon>
                        {{ $stratus.services.strings.capitalize(duration(row)) }}
                      </template>
                    </div>
                  </template>
                </sca-store-grid>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import _ from 'lodash'
import { getEventCategoryIcon, getCriticityStyle } from '@/services/services'
import EventTimelineItem from './EventTimelineItem'

export default {
  components: {
    'srs-event-timeline-item': EventTimelineItem
  },
  data () {
    return {
      columns: [
        {
          text: this.$t('Affected service'),
          value: 'category'
        },
        {
          text: this.$t('Outage type') + ' / ' + this.$t('Criticity / duration'),
          value: 'type'
        },
        {
          text: this.$t('Status'),
          value: 'status',
          format: value => this.$t(`event-status-${value}`)
        },
        {
          text: this.$t('Start date'),
          value: 'startTime',
          format: this.$stratus.services.fieldRenderers.DATETIME_LONG
        },
        {
          text: this.$t('End date'),
          value: 'endTime',
          format: (endTime, columns, row) => this.endTime(row)
        }
      ],
      expanded: {},
      getEventCategoryIcon,
      getCriticityStyle,
      isLoading: true,
      lastEvents: [],
      lastEventsByDate: [],
      lastEventsKeysByDate: [],
      tab: 'tab-timeline'
    }
  },
  computed: {
    existsNoByDate () {
      let found = false
      let i = 0
      while (!found && i < this.lastEvents.length) {
        found = this.lastEvents[i].byDate === false
        i++
      }
      return found
    },
    isLogged () { return this.$store.getters.isLogged },
    options () {
      return {
        allowColumnsVisible: false,
        csvExport: false,
        show: true,
        flat: true,
        tile: true,
        update: false,
        delete: this.isLogged,
        foreignFields: ['type', 'criticity', 'duration'],
        sortNatural: false,
        sortBy: 'startTime',
        sortDescending: true
      }
    }
  },
  methods: {
    duration (event) {
      if (!event.duration) {
        return ''
      }
      return this.$stratus.dt.duration(event.duration.value, event.duration.unit).humanize()
    },
    endTime (event) {
      return event.endTime ? this.$stratus.dt(event.endTime).format('LLL') : (this.$stratus.dt().isSameOrAfter(this.$stratus.dt(event.startTime)) ? this.$t('Pending...') : '')
    },
    formatTime (time) {
      return this.$stratus.dt(time).format('LLL')
    },
    humanizeDuration (duration) {
      const parsedDuration = this.$stratus.dt.duration(duration.value, duration.unit)
      return this.$stratus.services.strings.capitalize(parsedDuration.humanize())
    },
    isToday (date) {
      return date.startOf('day').isSame(this.$stratus.dt().startOf('day'))
    },
    showEvent ({ success, item }) {
      if (item) this.$router.push(`/events/${item.id}`).catch((error) => { console.warn(error) })
    },
    async deleteEvent ({ success, error, item }) {
      if (!this.isLogged) {
        return
      }
      await this.$store.dispatch('events/deleteEvent', item.id)
      this.$emit('delete')
    },
    refresh () {
      this.isLoading = true
      if (this.isLogged && this.$refs.eventsGrid) {
        this.$refs.eventsGrid.fetchData()
      }
      this.$nextTick(() => {
        this.$store.dispatch('services/get')
          .then(() => {
            return this.$store.dispatch('events/getFloating')
          })
          .then(() => {
            this.lastEvents = this.$store.getters['events/floatingEvents']
            this.lastEventsByDate = this.$store.getters['events/eventsByDate']
            this.lastEventsKeysByDate = _.sortBy(Object.keys(this.$store.getters['events/eventsByDate']), d => -Number(d))
          })
          .finally(() => {
            setTimeout(() => { this.isLoading = false }, 250)
          })
      })
    },
    toggleExpanded (event) {
      this.$set(this.expanded, event.id, !this.expanded[event.id])
    }
  },
  mounted () {
    this.refresh()
  }
}
</script>
